body {
	background-color: rgb(100, 100, 100);
	background-image: url('https://www.transparenttextures.com/patterns/asfalt-light.png');

	font-family: 'Space Mono', monospace;
}

h1,
h3 {
	border-left: rgb(22, 85, 168) solid 10px;
	padding-left: 10px;
}

.blank {
	background-color: rgb(40, 40, 40);
	transition-duration: 1000ms;
}
.blank:hover {
	background-color: whitesmoke;
}

.contact-icons a {
	margin: 5px;
	margin-bottom: 3px;
	padding: 3px;
	font-size: 50px;
	color: rgb(28, 28, 28);
}

.contact-icons a:hover {
	color: whitesmoke;
}

.content-div {
	height: 250px;
}

.dev-icons {
	font-size: 50px;
}

.nav-links {
	display: flex;
	font-size: 20px;
	text-align: end;
	justify-content: end;
	width: 100%;
}
.nav-links a {
	color: rgb(240, 240, 240);
	text-decoration: none;
}

.projects {
	font-size: 18px;
}

.projects a {
	padding: 3px;
	font-size: 25px;
	color: rgb(28, 28, 28);
}

.projects a:hover {
	color: whitesmoke;
}

.column {
	height: 500px;
}

.button {
	background-color: rgb(211, 96, 61);
}
